.familyBoard {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.familyTreeWrapper {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.familyTreeScrollWrapper {
  width: 100%;
  height: 900px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.familyTree {
  display: flex;
  width: 100%;
  min-width: 700px;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0 5px 0 5px;
  box-sizing: border-box;
}
.familyTree .layer {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
}
.familyTree .layer div {
  margin: 0;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border: 1px solid grey;
  background: var(--color-white);
}
.familyTree .layer div:nth-child(even) {
  background: var(--color-light-grey);
}
.familyTree .layer:nth-child(2) div {
  height: 25%;
}
.familyTree .layer:nth-child(3) div {
  height: 12.5%;
}
.familyTree .layer:nth-child(4) div {
  height: 6.25%;
}
.familyTree .layer p,
.familyTree .layer a {
  margin: 0;
  font-size: 14px;
}

@media (min-width: 900px) {
  .familyTreeWrapper {
    max-width: none;
  }
}
