@import './index.css';

.notificationWrapper {
    position: fixed;
    top: 60px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    opacity: 0;
    height: auto;
    min-height: 80px;
    width: 90%;
    max-width: 400px;
    background: var(--color-white);
    color: var(--color-black);
    box-shadow: 0 0 8px 2px rgba(100,100,100,0.1);
    border-radius: 10px;
    z-index: 1000000000;
    animation: showNotification 10s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.notificationWrapper p {
    font-size: 15px;
    font-weight: 400;
    color: var(--color-black);
}
.notificationWrapper .icon {
    font-size: 28px;
    color: var(--color-yellow);
    width: 40px;
}
/*
--shadow-input:0 1px 6px 0 #EFEFEF;
--shadow-form-input:0 1px 2px 0 #EFEFEF;
--border-input:1px solid #EFEFEF;
*/
.guideWrapper {
    width: auto;
    padding: 20px;
    background: rgb(250,250,250);
    color: var(--color-black);
    border-radius: 10px;
    border: var(--border-input);
    margin: 20px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.guideWrapper .text {
    display: flex;
    flex-direction: column;
}

.guideWrapper h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: var(--color-text-grey);
}

.guideWrapper p {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-grey);
}

.guideWrapper .icon {
    display: flex;
    font-size: 28px;
    color: var(--color-yellow);
}

.nav {
    box-shadow: 0 -4px 6px 0 rgba(100,100,100,0.1) !important;
    border: none;
    border-bottom: var(--border-input) !important;
    color: var(--color-text-grey) !important;
    margin: 0 auto !important;
    position: relative;
}

.navButton {
    position: absolute !important;
    right: 40px !important;
    top: 0px !important;
}

.navLink {
    color: initial !important;
    text-decoration: none !important;
    font-weight: 400 !important;
    letter-spacing: .7px !important;
}

@keyframes showNotification {
    0% { transform: translateY(-150px); opacity: 0; }
    10%  { transform: translateY(0); opacity: 1; }
    85%  { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-150px); opacity: 0; }
}

@media ( min-width: 900px ) {
    .guideWrapper {
        flex-direction: row;
        gap: 20px;
    }

    .guideWrapper .icon {
        font-size: 40px;
    }

    .guideWrapper h2 {
        font-size: 24px;
    }

    .guideWrapper p {
        font-size: 16px;
    }
}
