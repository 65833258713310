@import './index.css';

.footer {
	display: flex;
	flex-direction: row;
	align-content: left;
	flex-wrap: wrap;
	background:var(--color-white);
	height:auto;
	width: 100%;
	border-top: 1px solid red;
	padding: 40px;
	padding-top: 0;
	box-sizing: border-box;
	border-top: 1px solid var(--color-grey);
}

.footerColumn {
	display: flex;
	flex-direction: column;
	height: auto;
	flex-wrap: wrap;
	width: 250px;
	margin-top: 20px;
}
.footerLink, .footerText {
	font-size: 16px;
	padding-left: 30px;
	box-sizing: border-box;
	font-weight: 500;
	color:var(--color-dark-grey);
	height: auto;
	margin:0;
	margin-top: 20px;
	cursor: pointer;
	text-decoration: none;
	transition: all .25s;
}
.footerLink:hover {
	color: var(--color-black);

}
.footerText {
	font-weight: 400;
	color: var(--color-black);
}

@media ( min-width: 600px ) {
	.footerWrapper {
	    padding-bottom: 60px;
	}
}

