@import './index.css';

body {
	background: var(--color-bg-white);
}

.formWrapper {
	width: 90%;
	margin: 0 auto;
	margin-bottom: 60px;
	max-width: 1200px;
}

.searchWrapper {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	margin-top: 60px;
	margin-bottom: 60px;
	display: block;
}

.tableData {
    display: inline-block;
    width: 85px;
    height: auto;
    margin: 0;
    margin-left: 25px;
    text-align: left;
    font-size: 16px;
    color: var(--color-dark-grey);
    font-weight: 300;
    padding: 0;
}

.tableData {
    color: var(--color-black);
}

@media (min-width: 600px) {
	.tableData {
	    width: 110px;
	    font-size: 18px;
	}
}