@import './index.css';

.absoluteInputWrapper {
	position: relative;
}
.altSearchInput:focus {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.inputResultsWrapper {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 60px;
	max-height: 240px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	overflow-y: scroll;
	width: calc(100% - 2px);
	z-index: 10000;
	background:var(--color-white);
	border: 1px solid var(--color-grey);
	box-shadow: var(--shadow);
}

.compInputResultsWrapper {
	top: 118px;
}
.closedResults {
	display: none;
}
/* width */
.inputResultsWrapper::-webkit-scrollbar {
  width: 2px;
  border-radius: 5px;
}

/* Track */
.inputResultsWrapper::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.inputResultsWrapper::-webkit-scrollbar-thumb {
  background: var(--color-dark-grey);
  height: 50px;
  width: 5px;
  border-radius: 5px;
}

.parentSearchResult {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin:0 auto;
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 50px;
	background:var(--color-white);
	border-bottom: 1px solid var(--color-grey);
	cursor: pointer;
	outline: none;
}
.parentSearchResult:nth-child(even) {background: var(--color-white) }
.parentSearchResult:nth-child(odd) { background:var(--color-light-grey ) }

.parentSearchResult:hover {
	opacity: 0.8;
}

.parentSearchResultData {
	font-size: 14px !important;
}