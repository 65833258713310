/*FONTS*/
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
/* END FONTS */

* {
    font-family: 'Manrope', 'Open Sans', 'Nunito sans', sans-serif;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}
body {
    background-color: #FEFEFE;
    padding: 0;
    margin: 0;
}

:root {
    --color-black: #433C3C;
    --color-white: #FFFFFF;
    --color-bg-white: #FAFAFA;
    --color-grey: #EFEFEF;
    --color-dark-grey: #8e8e8e;
    --color-light-grey: #F9F9F9;
    --color-yellow: #FFC206;

    --shadow-input:0 1px 6px 0 #EFEFEF;
    --shadow-form-input:0 1px 2px 0 #EFEFEF;
    --border-input:1px solid #EFEFEF;

    --focus-shadow-input:0 2px 6px 0 #EFEFEF;
    --shadow: 0 0 6px 0 rgba(100,100,100,0.2);
}