@import './index.css';

.formWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 80px;
  padding: 0;
  box-sizing: border-box;
}
.sukupuuFormWrapper {
  margin-top: 120px;
  padding-bottom: 60px;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.formGroup {
  width: 90%;
  padding: 0;
  padding-bottom: 60px;
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
.fullWidthFormGroup {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
}
.inputGroup {
  vertical-align: top;
  width: 100%;
  display: inline-block;
  margin: 10px 15px 0 0;
}
.wideSelect {
  width: 100% !important;
}
.sukupuuFormBlock {
  margin-left: auto;
  width: 100%;
  padding: 0;
}
.formRow {
  display: flex;
  flex-direction: column;
}
.formLargeRow {
  display: block;
  width: 100%;
  margin-top: 40px;
}
.formLargeBlockWrapper {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
.formInputDoubleBlock {
  display: block;
  width: 100%;
  margin-top: 50px;
}
.rightRormGroup {
  margin-left: auto;
}
.flexBreak {
  flex-basis: 0;
  height: 0;
}
.altFormGroup {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--color-light-grey);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border: 1px solid var(--color-grey);
  border-bottom: none;
}
.altFormContent {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.altInspectLinkButton {
  position: absolute;
  top: 100px;
  width: 200px !important;
  background: var(--color-grey) !important;
  border: 1px solid rgb(220, 220, 220) !important;
  color: var(--color-black) !important;
  font-weight: 500 !important;
}
.altInspectNormalButton {
  width: 150px !important;
  margin: 5px 5px;
  background: var(--color-grey) !important;
  border: 1px solid rgb(220, 220, 220) !important;
  color: var(--color-black) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.flexbox {
  display: flex;
  flex-wrap: wrap;
}
.sukupuuButton {
  max-width: initial;
}

.disabled {
  opacity: 0.7;
}

.buttonSelectWrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
}
.buttonSelectWrapper button {
  text-align: left;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  background: var(--color-grey);
  border-radius: 5px;
  color: rgb(60, 60, 60);
  background: var(--color-light-grey);
  border: 1px solid rgb(200, 200, 200);
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  height: 50px;
  transition: all 0.3s;
}
.buttonSelectWrapper button .icon {
  position: absolute;
  right: 20px;
  top: 16px;
  font-size: 16px;
  color: var(--color-dark-grey);
  animation-name: 'full-turn-anim';
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}
@keyframes full-turn-anim {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0deg);
  }
}
@keyframes slide-from-up {
  from {
    transform: translateY(-60px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.buttonSelectWrapper .options {
  position: absolute;
  z-index: 100000;
  background: var(--color-white);
  top: 60px;
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
  border: 1px solid var(--color-grey);
  border-radius: 10px;
  box-shadow: 0 0 12px 1px rgba(220, 220, 220, 0.5);
  animation-name: 'slide-from-up';
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}
.buttonSelectWrapper .options div {
  width: 100%;
  height: auto;
  min-height: 80px;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  display: block;
  border-bottom: 1px solid var(--color-grey);
  background: var(--color-light-grey);
  font-size: 12px;
  cursor: pointer;
}
.buttonSelectWrapper .options div:hover {
  opacity: 0.6;
}
.buttonSelectWrapper .options div:nth-child(even) {
  background: var(--color-white);
}
.buttonSelectWrapper .options div p {
  margin: 0;
  width: 90%;
  display: inline-block;
  font-size: 16px;
  color: #333;
  vertical-align: top;
  margin-top: 10px;
  padding-right: 10px;
  font-weight: 500;
  box-sizing: border-box;
}
.buttonSelectWrapper div .icon {
  margin: 0;
  margin-top: 15px;
  width: 10%;
  color: var(--color-dark-grey);
  font-size: 22px;
}
.popupForm {
  z-index: 100000;
  background: var(--color-white);
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 100px;
  height: auto;
  width: 100%;
  max-width: 550px;
  padding: 40px;
  box-sizing: border-box;
  display: block;
  border: 1px solid var(--color-grey);
  border-radius: 10px;
  box-shadow: 0 0 12px 1px rgba(220, 220, 220, 0.5);
  animation-name: 'slide-from-up';
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
}
.popupForm h3 {
  font-size: 22px;
  font-weight: 500;
  color: var(--color-black);
  margin: 0;
}
.popupForm p {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark-grey);
  margin: 0;
  margin-top: 10px;
}
.popupForm button {
  cursor: pointer;
  background: var(--color-grey);
  border-radius: 5px;
  color: rgb(60, 60, 60);
  background: var(--color-light-grey);
  border: 1px solid rgb(240, 240, 240);
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  max-width: 150px;
  height: 40px;
  margin-top: 20px;
}

.lightRegInfoDiv {
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 5px;
  margin-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.lightRegInfoDiv .row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.lightRegInfoDiv .col:nth-child(even) {
  background: var(--color-grey);
}

.lightRegInfoDiv .row .col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.lightRegInfoDiv .row .col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.lightRegInfoDiv .row .col .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  display: flex;
  flex-basis: 50%;
  padding: 5px 0 5px 10px;
  align-self: center;
  margin: 0;
  box-sizing: border-box;
}

.lightRegInfoDiv .row .col .value {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-black);
  display: flex;
  flex-basis: 50%;
  padding: 5px 0 5px 10px;
  align-self: center;
  margin: 0;
  box-sizing: border-box;
}

.formInputBlock {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
}
.formLabel {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  margin-bottom: 10px;
  display: block;
}
.formInput {
  width: 100%;
  font-size: 16px;
  padding: 0;
  margin: 0;
  padding-left: 10px;
  height: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: var(--border-input);
  box-shadow: var(--shadow-form-input);
  box-sizing: border-box;
  color: var(--color-black);
  outline: none;
  transition: all 0.1s;
}
.formInput:focus {
  box-shadow: var(--focus-shadow-input);
  background: var(--color-white);
}
.whiteInput {
  background: var(--color-white);
}
.areaInput {
  resize: none;
  height: 100px;
  padding-top: 10px;
}
.doubleInput {
  margin-top: 10px;
}
.inputInfo {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark-grey);
}
.rowInputGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.selectWrapper {
  position: relative;
  display: flex;
  width: 30%;
  min-width: 90px;
  margin-right: 3.33%;
  margin-bottom: 10px;
}
.selectArrow {
  position: absolute;
  right: 5px;
  top: 16px;
  font-size: 16px;
  color: var(--color-dark-grey);
}

.altInspectButtonPentu {
  background: var(--color-grey) !important;
  border: 1px solid rgb(220, 220, 220) !important;
  color: var(--color-black) !important;
  font-weight: 500 !important;
  width: 140px !important;
}
.addedChildrenDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.childrenBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-grey);
  position: relative;
}
.childrenInfoItem,
.editChildrenItem {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-black);
  width: 30%;
  padding-right: 10px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 10px;
}
.smallChildrenInfo {
  width: 10%;
}
.editChildrenItem {
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
  text-align: right;
}

.formSentWrapper {
  animation-name: slide-wrapper-anim;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
}

.familyTreeDogName {
  font-size: 16px;
  font-weight: 300;
}
.familyDog {
  width: 100%;
  max-width: 400px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.familyDogName {
  width: 50%;
  min-width: 100px;
}

@keyframes slide-wrapper-anim {
  from {
    transform: translateX(5vw);
  }
  to {
    transform: translateX(0);
  }
}

.nonValidInput {
  border: 1px solid rgb(255, 150, 150);
}

@media (min-width: 900px) {
  .lightRegInfoDiv {
    background: var(--color-light-grey);
    border: var(--border-input);
    padding: 0 20px 20px 20px;
  }

  .lightRegInfoDiv .row {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .lightRegInfoDiv .col {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: transparent !important;
  }

  .lightRegInfoDiv .row .col .label {
    font-size: 16px;
    flex-basis: 100%;
    padding: 20px 0 0 0;
  }

  .lightRegInfoDiv .row .col .value {
    font-size: 16px;
    flex-basis: 100%;
    padding: 10px 0 0 0;
  }

  .flexbox {
    display: flex;
    flex-wrap: nowrap;
    margin-top: -40px;
    padding-bottom: 40px;
  }
  .altInspectNormalButton {
    width: 200px !important;
    margin: 10px 10px;
    background: var(--color-grey) !important;
    border: 1px solid rgb(220, 220, 220) !important;
    color: var(--color-black) !important;
    font-weight: 500 !important;
  }
  .formWrapper {
    margin-top: 80px;
  }
  .sukupuuFormWrapper {
    margin-top: 80px;
  }
  .altInspectLinkButton,
  .altInspectLink {
    top: 120px;
  }
  .form {
    flex-direction: row;
  }
  .formGroup {
    width: 50%;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
  }
  .sukupuuFormGroup {
    width: 90%;
    max-width: 900px;
  }
  .fullWidthFormGroup {
    width: 100%;
    max-width: none;
  }
  .inputGroup {
    width: 350px;
    display: inline-block;
  }
  .formRow {
    flex-direction: row;
    width: 100%;
  }
  .sukupuuFormBlock {
    width: 50%;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .formInputDoubleBlock {
    display: inline-block;
    width: 45%;
    margin-right: 5%;
    box-sizing: border-box;
  }
  .sukupuuInput {
    max-width: 300px;
  }
  .sukupuuButton {
    max-width: 250px;
  }
  .altFormGroup {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 80px;
    width: 50%;
    max-width: 500px;
    height: 625px;
    /* just a trick for it not being full col height */
    border: 1px solid var(--color-grey);
    border-radius: 15px;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 20px;
  }
  .flexBreak {
    flex-basis: 100%;
    height: 0;
  }
}
