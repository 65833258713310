@import './index.css';

.dataGridContainer {
    width: calc(100% - 10px);
    height: 900px;
    max-height: calc(100vh - 200px);
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 30px;
    display: block;
}

.searchWrapper {
    width: calc(100% - 10px);
	max-width: 600px;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 40px;
	display: block;
}

.formWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.form {
    width: 100%;
    max-width: 720px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 40px;
    text-align: center;
}

.form h3 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}

.form p {
    font-size: 14px;
    font-weight: 400;
    color: "#333";
    margin-top: 20px;
    margin-bottom: 20px;
}

.form button {
    margin-top: 40px;
    font-size: 14px;
    width: 100%;
    height: 50px;
}

@media ( min-width: 600px ) {
    .form {
        background: var(--color-white);
        border: var(--border-input);
    }
}