@import './index.css';

.navLink {
    display: inline-block;
    margin-left: 40px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 300;
    color:#333;
    display: none;
}

.link {
    text-decoration: none;
    color: initial;
}

.plainText{
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    box-sizing: border-box;
}
.contentWrapper {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    box-sizing: border-box;
    min-height: calc(100vh - 400px);
}
.genderChoice {
    position: relative;
    width: 48%;
    float: left;
    text-align: left;
}
.registerChoice {
    position: relative;
    width:48%;
    float: left;
    text-align: left;
}

.formControl select {
    display: none;
}

.inlineList {
    display: inline-block;
    width: auto;
    border-radius: 12px;
    min-width: 340px;
    vertical-align: top;
}

.inlineListContainer{
    height: 100%;
}

.formBlock {
    display: block;
    margin-top: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
}
.pageHeader, .altPageHeader, .smallPageHeader {
    font-size: 32px;
    font-weight: 500;
    color: var(--color-black);
}
.altPageHeader {
    font-size: 24px;
    color: var(--color-dark-grey);
}
.smallPageHeader {
    font-size: 20px;
}
.listButton {
    background:var(--color-black);
    color:var(--color-white);
    border:none;
    border-radius:4px;
    font-size: 14px;
    width: 100px;
    height: 40px;
    opacity: 1;
    cursor: pointer;
    transition: .25s;
    display: block;
    font-weight: 400;
}

.inspectButton, .fullButton {
    color:var(--color-white);
    background:var(--color-black);
    border:none;
    border-radius:4px;
    font-size: 14px;
    width: 100px;
    height: 40px;
    opacity: 1;
    cursor: pointer;
    transition: .25s;
    display: block;
}
.inspectButton:hover, .fullButton:hover {
    transform: translateY(-5px);
    opacity: .9;
}
.fullButton {
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 1px 2px 0 var(--color-dark-grey);
    margin-top: 20px;
}
.unavailableButton {
    opacity: 0.4 !important;
    cursor: default !important;
}
.unavailableButton:hover {
    transform: none;
}
.hidden {
    display: none;
}
.sorryInfoWrapper {
    padding-top: 10vh;
}
.searchLoadingDiv {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
    z-index: 1;
}
.loadingIcon {
    display: block;
    margin:0 auto;
    width: 40px;
    height: 40px;
    background:transparent;
    animation-name: loading-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    border:4px solid var(--color-light-grey);
    border-top: 4px solid var(--color-yellow);
    border-radius: 100%;
}
@keyframes loading-spin {
    from { transform: rotate(0)  }
    to  { transform: rotate(360deg)  }
}

.doneIcon {
    width: 40px;
    height: 40px;
    display: block;
    color: var(--color-yellow);
    /*margin:0 auto;*/
}

.notificationWrapper {
    position: fixed;
    top: 60px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    opacity: 0;
    height: auto;
    padding: 20px 10px 20px 20px;
    width: 90%;
    max-width: 300px;
    background: var(--color-white);
    color: var(--color-black);
    box-shadow: 0 0 8px 2px rgba(100,100,100,0.1);
    border-radius: 10px;
    z-index: 1000000000;
    animation: showNotification 5s;
}
.notificationWrapper * {
    display: inline-block;
    margin: 0;
    padding: 0;
}
.notificationWrapper .text {
    font-size: 15px;
    font-weight: 400;
    padding-left: 20px;
    color: var(--color-dark-grey);
}
.notificationWrapper .icon {
    font-size: 24px;
    color: var(--color-yellow);
    margin-bottom: -5px;
}

@keyframes showNotification {
    0% { transform: translateY(-150px); opacity: 0; }
    10%  { transform: translateY(0); opacity: 1; }
    85%  { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-150px); opacity: 0; }

}


@media ( min-width: 600px ) {
    .searchLoadingDiv {
        margin-top: 120px;
    }
}