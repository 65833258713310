.main * {
    box-sizing: border-box;
    margin: 0;
}

.main {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    width: 100%;
    min-height: calc(100vh - 300px);
    gap: 40px;
}

.mainBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
}

.headerWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 20px;
    gap: 20px;
}

.headerWrapper h2 {
    font-size: 28px;
    color: #333;
    font-weight: normal;
    width: 50%;
}

.headerWrapper span {
    font-size: 16px;
    color: #333;
    width: 50%;
}

.optionsWrapper {
    display: grid;
    gap: 20px;
    width: 100%;
    padding: 20px;
    /* we want a single row of 4 columns */
    grid-template-columns: repeat(4, 1fr);
}

.option {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(220, 220, 220);
    border-radius: 15px;
    padding-bottom: 20px;
}

.optionHeader {
    width: 100%;
    background: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 20px;
    height: 90px;
}

.option h3 {
    border-radius: 15px;
    font-weight: 400;
    font-size: 1.2rem;
    display: flex;
}

.option p {
    font-weight: 400;
    font-size: 0.8rem;
    padding: 20px;
    display: flex;
    flex-grow: 1;
}

.option button {
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    border-radius: 15px;
    height: 3.2rem;
    background: white;
    border: 1px solid rgb(220, 220, 220);
    cursor: pointer;
    transition: all 0.3s;
}

.option button:hover {
    background: rgb(230, 230, 230);
}

@media (max-width: 1100px) {

    .optionsWrapper {
        /* we want a two columns of n rows */
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 768px) {

    .main {
        gap: 20px;
    }

    .headerWrapper {
        margin-top: 20px;
        flex-direction: column;
    }

    .headerWrapper h2, .headerWrapper span {
        width: 100%;
        text-align: left;
    }

    .optionsWrapper {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        /* we want a single column of n rows */
        grid-template-columns: repeat(1, 1fr);
    }

    .option {
        width: 100%;
        padding-bottom: 10px;
    }

    .optionHeader {
        padding: 20px 10px;
        height: 60px;
    }

    .option h3 {
        font-size: 1rem;
    }

    .option p {
        padding: 20px 10px;
        font-size: 0.8rem;
        height: auto;
    }

    .option button {
        margin-left: 10px;
        margin-right: 10px;
    }

}
